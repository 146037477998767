<!--쿠폰 발급 페이지 메인-->
<template>
	<div>
		<!--검색 조건 카드 시작-->
		<searchCard>
			<div class="flex my-2">
				<div class="w-1/6 pl-8">
					<h6>키워드 검색</h6>
					<v-select v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
				</div>
				<div class="w-2/6 pl-4">
					<h6 class="block">&nbsp;</h6>
					<div class="flex">
						<b-form-input
							class=""
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
						<b-button class="w-40 top-0.5 ml-4" @click="search">검색</b-button>
					</div>
				</div>
			</div>
		</searchCard>

		<!--검색 결과 리스트 시작-->
		<b-card>
			<div class="mb-12">
				<!--				<div class="float-left">
					<b-button class="w-40" @click="">일괄 수정</b-button>
					<b-button class="ml-4 w-40" @click="" variant="gray">일괄 삭제</b-button>
				</div>-->
				<div class="float-right">
					<b-button class="w-40" @click="page('couponAdd')" variant="purple">쿠폰 생성하기</b-button>
					<!--					<b-button class="ml-4 w-40" @click="">엑셀 다운로드</b-button>-->
				</div>
			</div>
			<tb :inf="inf" :res="res" limit="40" />
		</b-card>
		<couponAddPopup :couponOpts="couponOpts" :pr="ths" />
	</div>
</template>

<script>
import couponAddPopup from 'pages/couponAddPopup'

const searchKeyOpts = [{ label: '쿠폰명', value: 'couponName' }],
	defOpt = {
		type: {},

		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}

export default {
	components: { couponAddPopup },
	data() {
		return {
			searchKeyOpts,
			defOpt,
			couponOpts: [],

			opt: { ...defOpt },
			lastOpt: {},
			res: {},
			origin: {},

			inf: [
				//{ model: 'checkAll' },
				{ title: 'No.', model: 'idx', size: 1 },

				{ title: '쿠폰 명', key: 'couponName', size: 12, notCenter: 1 },
				{ title: '쿠폰 번호', key: 'couponSeq', size: 1 },

				{ title: '할인조건', key: 'minOrderPrice', size: 4 },
				{ title: '할인금액', key: 'maxDiscountPrice', size: 3 },
				{ title: '발급 후<br>유효 기간', key: 'validPeriod', size: 2 },
				{ title: '쿠폰 사용 기간<br>시작일', key: 'startDt', size: 3 },
				{ title: '쿠폰 사용 기간<br>종료일', key: 'endDt', size: 3 },

				{ title: '중복 사용<br>가능 여부', key: 'isDuplicate', size: 3 },
				{ title: '내돈내산<br>전용 여부', key: 'onlySelfDeal', size: 3 },

				{ title: '쿠폰<br>사용량', key: 'couponCount', size: 5 },

				{ title: '등록일', key: 'createDt', size: 3 },
				{
					title: '수정/삭제',
					model: 'editDelete',
					size: 2,
					func1: v => page('couponAdd', { couponSeq: v.couponSeq }),
					func2: v => this.deleteCoupon(v),
				},

				{ title: '메모', model: 'memoBtn', domain: 'COUPON', size: 1 },
			],
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//검색 시작
			if (typeof operation != 'string') operation = 'LIKE'
			const opt = this.opt,
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword

			let data = {
					paging: getPaging(0, 40),
				},
				//선택 조건에 없는 필수값들 입력
				e = []

			//유효성 검사 및 서버에 보낼 데이터 채우기
			if (searchVal) {
				data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal)
				//if (data.searchKey.length > 1) data.searchKey.splice(data.searchKey.length - 1, 1)
				//메모가 들어가면 검색이 이상해진다?
			}
			if (e.length)
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.w(error)
			else {
				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				//this.selectedItem = {}
				return this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			return postApi('api/backoffice/couponSelect/couponList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					this.origin = copyVar(res)

					res.list = res.list.map(v => {
						v.minOrderPrice = numFormat(v.minOrderPrice) + '원 이상 구매 시'
						v.maxDiscountPrice = numFormat(v.discountPrice) + '원 할인'
						v.isDuplicate = v.isDuplicate ? 'O' : 'X'
						v.onlySelfDeal = v.onlySelfDeal ? 'O' : 'X'
						if (v.saveStatus != 'Y') v.trClass = 'text-gray-light'

						v.couponCount = comma(v.usedCouponCount + ' / ' + v.publishedCouponCount)
						return v
					})

					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()

			/*if (opt.startDate) ex.key('날짜 형식', '승인일')
			ex.date(opt.startDate, opt.endDate)

			if (opt.starterStep) ex.dropdown(opt.starterStep, this.stepOpts, '진행상태')

			if (opt.hottistType) ex.dropdown(opt.hottistType, this.typeOpts, '핫티스트 구분')*/

			ex.search(opt.searchKey, searchKeyOpts)

			reload(this, false, ex)
		},

		deleteCoupon(v) {
			putApi('api/backoffice/couponHandle/updateCoupon', {
				couponSeq: v.couponSeq,
				isDelete: true,
			}).then(() => {
				alert.s('쿠폰 삭제가 정상 처리 되었습니다')
				reload(this)
			})
		},
	},
	created() {
		this.search()
	},
}
</script>
